// Utilizzo di idb, una piccola libreria per facilitare l'uso di IndexedDB
import { openDB } from 'idb';

async function initDB() {
  const db = await openDB('images-store', 1, {
    upgrade(db) {
      db.createObjectStore('images');
    },
  });
  return db;
}

async function cacheImage(url:string):Promise<boolean> {
  const db = await initDB();
  const response = await fetch(url);
  if(response.ok){
    const blob = await response.blob();
    await db.put('images', blob, url);
    return true;
  }
  return false;
}

async function getCachedImage(url:string) {
  const db = await initDB();
  return await db.get('images', url);
}

export { cacheImage, getCachedImage };
