import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { cacheImage, getCachedImage } from '../../utils/db';

interface IImageLoader {
    src: string;
    style?:any;
    className?: string;
}
const ImageLoader = ({ src, style, className }:IImageLoader) => {
  const [loaded, setLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>(null);

  useEffect(() => {
    const loadImage = async () => {
        try {
          let cachedImage = await getCachedImage(src);
          if (cachedImage) {
            setImageSrc(URL.createObjectURL(cachedImage));
          } else {
            const response = await cacheImage(src);
            if(response){
              cachedImage = await getCachedImage(src);
              setImageSrc(URL.createObjectURL(cachedImage));
            }else{
              setImageSrc(src)
            }
          }
          setLoaded(true)
        } catch (error) {
          setImageSrc(src)
          setLoaded(true)
        }
      };
  
      loadImage();
  }, [src]);

  return (
    <div className="image-container">
      <CSSTransition
        in={loaded}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <img
          className={`lazy-image ${className}`}
          src={imageSrc}
          style={{ display: loaded ? 'block' : 'none', ...(style ?? {}) }}
        />
      </CSSTransition>
    </div>
  );
};

export default ImageLoader;
