import { useEffect, useState } from "react";
import { Content } from "./type/content";
import { getContents } from "./network/network";
import VideoFullScreen from "./components/Video";

const Video = () => {
    const [content, setContent] = useState<Content>();

    async function downloadSlides() {
        const _contents = await getContents();
        const videoContent = _contents?.sort((a, b) => a.id - b.id)?.[4];
        console.log(">>",videoContent?.attributes?.Content[0].Contents?.data[0].attributes.url!)
        setContent(videoContent)
    }
    useEffect(() => {
        downloadSlides();
    }, [])
    return content ? (
                <VideoFullScreen src={content?.attributes?.Content[0].Contents?.data[0].attributes.url!} />
    ) :  null
}

export default Video;