import { useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { getContents } from "./network/network";
import { Content } from "./type/content";
import ImageLoader from "./components/ImageLoader";
import { useAutoRefresh } from "./hooks/autoRefresh";
import { AUTO_REFRESH_TIMEOUT } from "./consts";
import { sendSwipe, sendClick } from "./utils/gtm";

interface ICard {
  id: string
  image: string;
  onChangeSlide: () => void;
}
function Card(props: ICard) {
  return (
    <button id={props.id} onClick={() => {
      sendClick(props.id)
      props.onChangeSlide()
    }} style={{ border: 0, backgroundColor: "transparent" }}>
      <ImageLoader src={props.image} style={{ width: "11vh", objectFit: "contain" }} />
    </button>
  )
}

interface SlideContentProps {
  isActive: boolean,
  isFirstTimeSliderView: React.MutableRefObject<boolean>
  imageUrl: string,
  slideName: string
}

function SlideContent({ isActive, imageUrl, slideName, isFirstTimeSliderView }: SlideContentProps) {

  useEffect(() => {
    if (isActive) {
      if (!isFirstTimeSliderView.current) {
        sendSwipe(slideName)
      } else {
        isFirstTimeSliderView.current = false
      }
    }
  }, [isActive])


  return (
    <ImageLoader style={{ height: "100vh", width: "100%", objectFit: "fill" }} className="slide-img"
      src={imageUrl} />
  )

}
function Main() {
  const [language, setLanguage] = useState<"it" | "en">("it");
  const [view, setView] = useState<"list" | "detail">("list");
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [contents, setContents] = useState<Content[]>([]);
  const queryParameters = new URLSearchParams(window.location.search)
  const digitalSignageId: number = Number(queryParameters?.get("ds"));
  const { setAutoRefreshTimeout, restartAutoRefreshTimeout, stopAutoRefresh } = useAutoRefresh()
  const firstInteractionHappen = useRef(false)
  const isFirstTimeSliderView = useRef(false)

  const COUNT_IMAGES_FLOW_SERVICES = 5;

  async function downloadSlides() {
    const _contents = await getContents();
    setContents(_contents)
  }
  useEffect(() => {
    downloadSlides();
  }, [])

  function handleInteraction() {
    if (!firstInteractionHappen.current) {
      firstInteractionHappen.current = true
      setAutoRefreshTimeout(AUTO_REFRESH_TIMEOUT)
    } else {
      restartAutoRefreshTimeout()
    }
  }
  function onChangeSlide(slideIndex: number) {
    handleInteraction()
    setView("detail")
    isFirstTimeSliderView.current = true
    setCurrentSlide(slideIndex)
  }
  function onCloseCarousel() {
    //stopAutoRefresh()
    //firstInteractionHappen.current = false
    handleInteraction()
    isFirstTimeSliderView.current = false
    setView("list")
    setCurrentSlide(0)
  }

  function getId(prefix: string, index: number): string {
    const ids = ["shop24h", "click-collect", "full-collection-access", "tailor-shop",
      "levis-red-tab-member-program", "love-levis", "free-shipping", "members-drop", "early-access",
      "birthday-surprises", "exlusive-competition", "welcome-gift"]

    return `${prefix}-${ids[index]}-${language}`
  }

  function getListContents() {
    return contents?.find(c => language === "it" ? c.id === 1 : c.id === 3)
  }
  function getDetailsContents() {
    return contents?.find(c => language === "it" ? c.id === 2 : c.id === 4)
  }
  function getImagePreview(index: number): string {
    return getListContents()?.attributes?.Content?.filter(c => c.__component === "content.image")?.[index]?.Images?.data?.[0]?.attributes?.url || "";
  }
  function switchLanguage() {
    setLanguage(prevLang => prevLang === "en" ? "it" : "en")
  }

  return (
    <div className="App" style={{ position: "relative", height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
      <TransitionGroup component={null}>
        {
          view === "list" ?
            <CSSTransition in={view === "list"} key={"list"} timeout={500} classNames="fade" unmountOnExit>
              <div className="whole-container">
                <button style={{ border: 0, margin: 0, padding: 0, position: "fixed", zIndex: 99999, bottom: 50, right: 50, background: "transparent" }}
                  onClick={() => {
                    handleInteraction()
                    switchLanguage()
                  }}>
                  <img src={`/images/bandiera-${language !== "en" ? "eng" : "ita"}.png`} style={{ height: "4vh", width: "4vh" }} />
                </button>
                <ImageLoader
                  style={{ objectFit: "fill", overflow: "hidden", height: "100%", width: "100%" }}
                  src={`/images/${language === "en" ? "home-eng.jpg" : "home-ita.png"}`} />
                <div className='container-btns' style={{ top: "19vh" }}>
                  <Card id={getId('button', 0)} image={getImagePreview(0)} onChangeSlide={() => onChangeSlide(0)} />
                  <Card id={getId('button', 1)} image={getImagePreview(1)} onChangeSlide={() => onChangeSlide(1)} />
                  <Card id={getId('button', 2)} image={getImagePreview(2)} onChangeSlide={() => onChangeSlide(2)} />
                  <Card id={getId('button', 3)} image={getImagePreview(3)} onChangeSlide={() => onChangeSlide(3)} />
                </div>
                <div className='container-btns' style={{ top: "34vh" }}>
                  <Card id={getId('button', 4)} image={getImagePreview(4)} onChangeSlide={() => onChangeSlide(4)} />
                </div>
                <div className='container-btns' style={{ top: "58vh" }}>
                  <Card id={getId('button', 5)} image={getImagePreview(5)} onChangeSlide={() => onChangeSlide(5)} />
                  <Card id={getId('button', 6)} image={getImagePreview(6)} onChangeSlide={() => onChangeSlide(6)} />
                  <Card id={getId('button', 7)} image={getImagePreview(7)} onChangeSlide={() => onChangeSlide(7)} />
                  <Card id={getId('button', 8)} image={getImagePreview(8)} onChangeSlide={() => onChangeSlide(8)} />
                </div>
                <div className='container-btns' style={{ top: "73vh" }}>
                  <Card id={getId('button', 9)} image={getImagePreview(9)} onChangeSlide={() => onChangeSlide(9)} />
                  <Card id={getId('button', 10)} image={getImagePreview(10)} onChangeSlide={() => onChangeSlide(10)} />
                  <Card id={getId('button', 11)} image={getImagePreview(11)} onChangeSlide={() => onChangeSlide(11)} />

                </div>
              </div>

            </CSSTransition>
            :
            <CSSTransition key="detail" in={view === "detail"}
              timeout={500}
              classNames="fade"
              unmountOnExit>

              <div className="whole-container">
                <button style={{ border: 0, margin: 0, padding: 0, position: "fixed", zIndex: 99999, top: "4vh", right: "4vh", background: "transparent" }}
                  onClick={onCloseCarousel}>
                  <img src="/images/letter-x_109602.svg" style={{ height: "6vh", width: "6vh" }} />
                </button>
                <button style={{ border: 0, margin: 0, padding: 0, position: "fixed", zIndex: 99999, bottom: 50, right: 50, background: "transparent" }}
                  onClick={() => {
                    handleInteraction()
                    switchLanguage()
                  }}>
                  <img src={`/images/bandiera-${language !== "en" ? "eng" : "ita"}.png`} style={{ height: "4vh", width: "4vh" }} />
                </button>

                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  pagination={true}
                  initialSlide={currentSlide > COUNT_IMAGES_FLOW_SERVICES - 1 ? currentSlide - COUNT_IMAGES_FLOW_SERVICES : currentSlide}
                  onSlideChange={() => {
                    handleInteraction()
                  }}>
                  {
                    getDetailsContents()?.attributes?.Content?.filter(c => c.__component === "content.image")?.
                      slice(currentSlide > COUNT_IMAGES_FLOW_SERVICES - 1 ? COUNT_IMAGES_FLOW_SERVICES : 0, currentSlide > COUNT_IMAGES_FLOW_SERVICES - 1 ? undefined : COUNT_IMAGES_FLOW_SERVICES).
                      map((image, indexImage) => {
                        const indexImageForFs = (image.Images?.data?.length === 1 || !digitalSignageId) ? 0 : digitalSignageId === 2 ? 1 : 0
                        const imageUrl = image.Images?.data?.[indexImageForFs]?.attributes?.url || ""
                        return (<SwiperSlide id={getId('slide', indexImage)} key={indexImage}>
                          {({ isActive }) => (
                            <SlideContent isFirstTimeSliderView={isFirstTimeSliderView} isActive={isActive} slideName={getId('slide', indexImage)} imageUrl={imageUrl}></SlideContent>
                          )}
                        </SwiperSlide>)
                      })
                  }
                </Swiper>
              </div>
            </CSSTransition>
        }
      </TransitionGroup>
    </div>
  );
}

export default Main;
