import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_STRAPI_URL}/api`, // URL base del tuo server Strapi
  timeout: 10000, // timeout per le richieste
});

// Interceptor per le richieste
axiosInstance.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_TOKEN;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor per le risposte
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Gestisci gli errori qui (es. refresh del token, logout, notifiche)
    if (error.response && error.response.status === 401) {
      // Gestisci il caso in cui l'utente non è autorizzato
      console.error('Non autorizzato, effettua il login.');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
