import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { cacheImage as cacheVideo, getCachedImage as getCachedVideo } from '../../utils/db';

interface IVideoLoader {
    src: string;
    style?: any;
    className?: string;
}
const VideoFullScreen = ({ src, style, className }: IVideoLoader) => {
    return (
        <video style={{ height: "100%", width: "100%", minWidth:"100%", minHeight:"100%" }} loop autoPlay muted>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

export default VideoFullScreen;
