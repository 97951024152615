export const sendEvent = (event: any) : void => {
    try {
        //@ts-ignore
        window.dataLayer = window.dataLayer || [];
        //@ts-ignore
        dataLayer.push({ ecommerce: null });
        //@ts-ignore
        dataLayer.push(event);
      } catch (error) {
        console.log("[ERROR] During GTM trackAddToCartEvent")
      }
} 

export const sendSwipe = (slide: string) : void => {
    sendEvent({
        event: 'swipe',
        slide
    })
}

export const sendClick = (button: string) : void => {
  sendEvent({
      event: 'click',
      button
  })
}