import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Video from "./Video";
import Main from "./Main";



export default function RouterApp() {
  return (
    <Router>
      <Routes>
        <>
          <Route path='/' element={<Main />} />
          <Route path='/video' element={<Video />} />
          <Route path='*' element={<Navigate to='/' />} />
        </>
      </Routes>
    </Router>
  );
}
