import { useLocation } from "react-router-dom"

let timeout : NodeJS.Timeout | undefined
let ms : number | undefined


/**
 * Hook for refresh the page after some times
 */
export const useAutoRefresh = (whitelist? : string[]) => {
    const location = useLocation()

    const setRefreshTimeout = () => {
        if(timeout){
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            if(whitelist && whitelist.includes(location.pathname)){
                setRefreshTimeout()
            } else {
                window.location.reload()
            }
        }, ms)
    } 
    return {
        setAutoRefreshTimeout: (millisec: number) => {
            ms = millisec
            console.log('set new timeout', ms, location.pathname)
            setRefreshTimeout()
        },
        restartAutoRefreshTimeout: () => {
            console.log('restart timeout', ms, location.pathname)
            setRefreshTimeout()
        },
        stopAutoRefresh: () => {
            console.log('stop auto refresh')
            clearTimeout(timeout)
        }

    }
}